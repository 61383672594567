function MainPageContent() {

    const getLsAge = () => {
        const today = new Date();
        const birthDate = new Date('1998-07-14');
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        const dayDifference = today.getDate() - birthDate.getDate();
        
        if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
            age--;
        }

        return age.toString();
    };

    return (
        <article id="main-article">
            
            <div id="sections-wrapper">

                <section id="anchorAboutBook">
                    <h2 className="h2Article">
                        <span className="highlight-container2">
                            <span className="highlight">
                                About the Book
                            </span>
                        </span>
                    </h2>
                    <p>
                        This book is your definitive strategy guide to conquering the gigantic task of learning a language or, for the daring, <i>multiple languages</i>. I know firsthand that getting started can be confusing, and that the road is filled with potential traps and pitfalls. I have also found that so many resources out there are needlessly technical and confusing, bombarding our feeble human minds with nerdy nonsense that is totally unnecessary for a beginner. 
                    <br /><br />
                        So, I've put my years of language-learning experience to work and manifested this handbook into existence through my gigachad willpower, in order to guide you away from those easily avoidable mistakes and towards the coveted life cheat code we call "polyglotism" which is sure to unlock tons of DLC for you on this cute little floating ball of rock we call Earth. 
                    <br /><br />
                        I'm not here to sell you on some cheap hack and I can't promise you that you'll finish your quest instantly like you downloaded a complete save file off a sketchy forum, but I can promise you that the insights in my book can help you make it <i>fun</i> and <i>rewarding</i>. If you're ready to graduate from monolingualism, this is the book for you.                     </p>
                    <div className="divYtContainer"></div>
                </section>

                <section id="anchorWhy">
                    <img id="image_1" alt="Drawing of a very handsome hyperpolyglot gigachad surrounded by letters from foreign alphabets, holding a pencil, ready to learn." src={require("./images/chad_1.webp")} />
                    <h2 className="h2Article">
                        <span className="highlight-container3">
                            <span id="new-language-text" className="highlight">
                                Why Learn a New Language?
                            </span>
                        </span>
                    </h2>
                    <p>
                        I can't speak for everyone, but in America (the greatest nation on this Earth), everyone is forced to take foreign language classes in school, and nearly everyone hates it. This experience is unnecessary suffering and is a complete travesty because learning to speak a foreign language should really be one of the most amazingly fun things that anyone could do. 
                        <br /><br />
                        I know it might be hard, but forget your childhood trauma from those boring classes for a moment, and imagine how fun it would be to Xiaoma-shock native speakers in your local Chinatown with fluent Chinese, or to order a Big Mac at a McDonald's in Ireland in Scottish Gaelic. These are just a couple of great examples of crazy DLC you can unlock in this silly game we call life if you become competent in a foreign language. 
                        <br /><br />
                        Speaking foreign languages can open your eyes to a world of travel opportunities and expose you to diverse cultures, media, and music that you otherwise would never have known that you would love. Maybe you could even find the woman... or man of your dreams in a faraway land! Frankly, it's hard to think of reasons NOT to learn a new language, and the best time to get started is <i>now</i>. 

                    </p>
                </section>

                <section id="anchorWho">
                    <h2 className="h2Article">
                        <img id="image_2" alt="Drawing of a very handsome hyperpolyglot gigachad lounging on a chair while reading the book." src={require("./images/chad_2.webp")} />
                        <span className="highlight-container4">
                            <span className="highlight">
                                Who's the Book For?
                            </span>
                        </span> 
                    </h2>
                    <p>
                        Are you an aspiring gigachad hyperpolyglot, a monolingual beta ready to shake off the chains of ignorance, someone who has been desperately wanting to get started on their language learning journey but is confused by all the contradictory information out there, or a curious bystander wondering what all this polyglotism tomfoolery is all about? If you are any one or combination of these, then this book is for you. 
                        <br /><br />
                        If, however, you <i>HATE</i> languages and have absolutely no curiosity or fun in your heart whatsoever, stay far away from this book. It might endanger your lifestyle or challenge your beliefs in an irreversibly damaging manner. You have been warned.
                    </p>
                </section>
                
                <section id="anchorAboutSimp">
                    <img id="image_3" alt="Drawing of Language Simp (very muscular) flexing while inspirationally smiling at the viewer." src={require("./images/ls.webp")} />
                    <h2 className="h2Article">
                        <span className="highlight-container5">
                            <span className="highlight">
                                About Me
                            </span>
                        </span>
                    </h2>
                    <p>
                    My name is Language Simp, and I am a {getLsAge()} year-old hyperpolyglot gigachad alphamale who is attractive to every woman... and man on the planet. I didn't start off that way, though. I, like many other humans, started off as a weak monolingual beta who could speak only one language (although it <i>is</i> the greatest language, American). 
                    <br /><br />
                    But, years ago I had a fateful encounter and budding romance with the French language which got me started down a path that I have never looked back from since. From French, I branched out into many other languages such as Spanish, Russian, Portuguese, and Arabic, among others. Not a single day goes by where I do not think about, use, or study languages. 
                    <br /><br />
                    Then, just over two years ago I started my YouTube channel, Language Simp, hoping to spread the joy of language learning and convert the global population to the cult of multilingualism and polyglotism. 
                    <br /><br />
                    After seeing monumental success with the channel and getting hundreds of requests in comments on my YouTube, Patreon, and Discord from people begging me to tell them how I learn languages, I decided to codify my knowledge and experience into a book, and that leads me to where I am today. I hope you find my book as useful and entertaining to read as it was fun for me to write it!

                    </p>
                    
                    <div id="socials-wrapper">
                        <a href="https://www.instagram.com/thelanguagesimp/">
                            <img alt="instagram logo" id="is-icon" className="socials-icon" src={require("./images/instagram-icon.webp")} />
                        </a>
                        <a href="https://www.youtube.com/@LanguageSimp">
                            <img alt="youtube logo" id="yt-icon" className="socials-icon" src={require("./images/youtube-icon.webp")} />
                        </a>
                        <a href="https://www.tiktok.com/@languagesimp">
                            <img alt="tiktok logo" id="tk-icon" className="socials-icon" src={require("./images/tiktok-icon.webp")} />
                        </a>
                        <a href="https://discord.gg/languagesimp">
                            <img alt="discord logo" id="ds-icon" className="socials-icon" src={require("./images/discord-icon.png")} />
                        </a>
                    </div>
                </section>

                <img alt="A glorious illustration of the American flag flying in the wind." id="image_4" src={require("./images/murrica.webp")} />

                
            </div>
            
        </article>
    );
}

export default MainPageContent;